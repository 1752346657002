import React, { useState, useEffect, useRef } from "react";
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from "../components/ConfirmationDialogRaw";
import { FONT_FAMILY, SECONDORY_COLOR, downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import {
  Grid,
  Typography,
  Modal,
  Button,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  Paper,
  Tooltip,
} from "@mui/material";
import { api } from "common";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import UsersCombo from "../components/UsersCombo";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import CancelIcon from "@mui/icons-material/Cancel";
import PaymentIcon from "@mui/icons-material/Payment";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AlertDialog from "../components/AlertDialog";
import { colors } from "../components/Theme/WebTheme";
import { useNavigate } from "react-router-dom";
import {
  bookingHistoryColumns,
  BidModal,
  acceptBid,
  showEst,
} from "../common/sharedFunctions";
import { getDatabase, ref, update } from "firebase/database";
import { ThemeProvider } from '@mui/material/styles';
import theme from "styles/tableStyle";
import jsPDF from 'jspdf';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${colors.BLACK}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  action: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
    borderRadius: "20px",
    paddingLeft: "10px",
    paddingRight: "10px",
    width: "10rem",
    minHeight:"40px"
  },
}));

const icons = {
  paypal: require("../assets/payment-icons/paypal-logo.png").default,
  braintree: require("../assets/payment-icons/braintree-logo.png").default,
  stripe: require("../assets/payment-icons/stripe-logo.png").default,
  paytm: require("../assets/payment-icons/paytm-logo.png").default,
  payulatam: require("../assets/payment-icons/payulatam-logo.png").default,
  flutterwave: require("../assets/payment-icons/flutterwave-logo.png").default,
  paystack: require("../assets/payment-icons/paystack-logo.png").default,
  securepay: require("../assets/payment-icons/securepay-logo.png").default,
  payfast: require("../assets/payment-icons/payfast-logo.png").default,
  liqpay: require("../assets/payment-icons/liqpay-logo.png").default,
  culqi: require("../assets/payment-icons/culqi-logo.png").default,
  mercadopago: require("../assets/payment-icons/mercadopago-logo.png").default,
  squareup: require("../assets/payment-icons/squareup-logo.png").default,
  wipay: require("../assets/payment-icons/wipay-logo.png").default,
  test: require("../assets/payment-icons/test-logo.png").default,
  razorpay: require("../assets/payment-icons/razorpay-logo.png").default,
  paymongo:require("../assets/payment-icons/paymongo-logo.png").default,
  iyzico:require("../assets/payment-icons/iyzico-logo.png").default,
};

const BookingHistory = (props) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const { cancelBooking, updateBooking, RequestPushMsg, forceEndBooking } = api;
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const userdata = useSelector((state) => state.usersdata);
  const settings = useSelector((state) => state.settingsdata.settings);
  const role = useSelector((state) => state.auth.profile.usertype);
  const [paymentModalStatus, setPaymentModalStatus] = useState(false);
  const providers = useSelector((state) => state.paymentmethods.providers);
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedProviderIndex, setSelectedProviderIndex] = useState(0);
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState();
  const bookinglistdata = useSelector((state) => state.bookinglistdata);
  const [users, setUsers] = useState(null);
  const [userCombo, setUserCombo] = useState(null);
  const rootRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [bidModalStatus, setBidModalStatus] = useState(true);
  const [selectedBidder, setSelectedBidder] = useState();
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const classes = useStyles();
  const newColumn = {
    title: t('DISTANCIA REAL RECORRIDA'),
    field: 'distance', 
    render: rowData => (
      <span>
        {rowData.distance ? 
          (settings.convert_to_mile ? 
            (parseFloat(rowData.distance) * 0.621371).toFixed(2) + ' mi' : 
            parseFloat(rowData.distance).toFixed(2) + ' km'
          ) : 
          t('no_distance')
        }
      </span>
    )
  };
  
  // Agrega esta columna al array de columns existente
  const columns = bookingHistoryColumns(role, settings, t, isRTL).filter(column => column.field !== 'deliveryInstructions').concat(newColumn);
  const [walletModalStatus, setWalletModalStatus] = useState(false);

  useEffect(() => {
    if (bookinglistdata.bookings) {
      setData(bookinglistdata.bookings);
    } else {
      setData([]);
    }
  }, [bookinglistdata.bookings]);

  useEffect(() => {
    if (userdata.users) {
      let arr = [];
      for (let i = 0; i < userdata.users.length; i++) {
        let user = userdata.users[i];
        if (
          user.usertype === "driver" &&
          ((user.fleetadmin === auth.profile.uid && role === "fleetadmin") ||
            role === "admin")
        ) {
          arr.push({
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            email: user.email,
            uid: user.id,
            desc:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              (settings.AllowCriticalEditsAdmin
                ? user.mobile
                : t("hidden_demo")) +
              ") " +
              (settings.AllowCriticalEditsAdmin
                ? user.email
                : t("hidden_demo")),
            pushToken: user.pushToken ? user.pushToken : "",
            carType: user.carType,
          });
        }
      }
      setUsers(arr);
    }
  }, [
    userdata.users,
    settings.AllowCriticalEditsAdmin,
    auth.profile.uid,
    role,
    t,
  ]);

  const assignDriver = () => {
    let booking = data[rowIndex];
    if (booking["requestedDrivers"]) {
      booking["requestedDrivers"][userCombo.uid] = true;
    } else {
      booking["requestedDrivers"] = {};
      booking["requestedDrivers"][userCombo.uid] = true;
    }
    dispatch(updateBooking(booking));
    RequestPushMsg(userCombo.pushToken, {
      title: t("notification_title"),
      msg: t("new_booking_notification"),
      screen: "DriverTrips",
      channelId: settings.CarHornRepeat ? "bookings-repeat" : "bookings",
    });
    setUserCombo(null);
    handleClose();
    alert("Conductor asignado con éxito y notificado.");
  };

  const onConfirmClose = (value) => {
    if (value) {
      dispatch(
        cancelBooking({
          reason: value,
          booking: selectedBooking,
          cancelledBy: role,
        })
      );
    }
    setOpenConfirm(false);
  };

  const handleChange = (e) => {
    if (e.target.name === "selectedProviderIndex") {
      setSelectedProviderIndex(parseInt(e.target.value));
      setSelectedProvider(providers[parseInt(e.target.value)]);
    }
    if (e.target.name === "selectedBidder") {
      setSelectedBidder(e.target.value);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePaymentModalClose = (e) => {
    setTimeout(() => {
      setPaymentModalStatus(false);
    }, 1500);
  };

  const handleWalletModalClose = (e) => {
    setTimeout(() => {
      setWalletModalStatus(false);
    }, 1500);
  };

  useEffect(() => {
    if (providers) {
      setSelectedProvider(providers[0]);
    }
  }, [providers]);

  const processPayment = (rowData) => {
    const curBooking = rowData;
    if (curBooking.payment_mode === "card") {
      let paymentCost = curBooking.selectedBid
        ? curBooking.selectedBid.trip_cost
        : curBooking.trip_cost;
      const paymentPacket = {
        payment_mode: "card",
        customer_paid: parseFloat(paymentCost).toFixed(settings.decimal),
        cardPaymentAmount: parseFloat(paymentCost).toFixed(settings.decimal),
        discount: 0,
        usedWalletMoney: 0,
        cashPaymentAmount: 0,
        promo_applied: false,
        promo_details: null,
        payableAmount: parseFloat(paymentCost).toFixed(settings.decimal),
      };
      curBooking.paymentPacket = paymentPacket;
      dispatch(updateBooking(curBooking));
      setSelectedBooking(curBooking);
      setPaymentModalStatus(true);
    }
    if (curBooking.payment_mode === "wallet") {
      let curBooking = { ...rowData };
      if (rowData.status === "PAYMENT_PENDING") {
        curBooking.status = "NEW";
        curBooking.prepaid = true;
      } else if (rowData.status === "PENDING") {
        curBooking.status = "PAID";
      } else if (rowData.status === "NEW") {
        curBooking.prepaid = true;
        curBooking.status = "ACCEPTED";
      }

      curBooking.customer_paid =
        curBooking.status === "ACCEPTED"
          ? 0
          : parseFloat(curBooking.trip_cost).toFixed(settings.decimal);
      curBooking.discount = 0;
      curBooking.usedWalletMoney =
        curBooking.status === "ACCEPTED"
          ? 0
          : parseFloat(curBooking.trip_cost).toFixed(settings.decimal);
      curBooking.cardPaymentAmount = 0;
      curBooking.cashPaymentAmount = 0;
      curBooking.payableAmount =
        curBooking.status === "ACCEPTED"
          ? parseFloat(curBooking.selectedBid.trip_cost).toFixed(
              settings.decimal
            )
          : parseFloat(curBooking.trip_cost).toFixed(settings.decimal);

      if (curBooking.status === "ACCEPTED") {
        curBooking.driver = curBooking.selectedBid.driver;
        curBooking.driver_image = curBooking.selectedBid.driver_image;
        curBooking.driver_name = curBooking.selectedBid.driver_name;
        curBooking.driver_contact = curBooking.selectedBid.driver_contact;
        curBooking.driver_token = curBooking.selectedBid.driver_token;
        curBooking.vehicle_number = curBooking.selectedBid.vehicle_number;
        curBooking.vehicleModel = curBooking.selectedBid.vehicleModel;
        curBooking.vehicleMake = curBooking.selectedBid.vehicleMake;
        curBooking.driverRating = curBooking.selectedBid.driverRating;
        curBooking.trip_cost = curBooking.selectedBid.trip_cost;
        curBooking.convenience_fees = curBooking.selectedBid.convenience_fees;
        curBooking.driver_share = curBooking.selectedBid.driver_share;
        curBooking.fleetadmin = curBooking.selectedBid.fleetadmin ? curBooking.selectedBid.fleetadmin : null;
        curBooking.fleetCommission= curBooking.selectedBid.fleetadmin ? ((parseFloat( curBooking.selectedBid.trip_cost) - parseFloat(curBooking.selectedBid.convenience_fees)) * (parseFloat(curBooking.fleet_admin_comission) / 100)).toFixed(2) : null;
        curBooking.driverOffers = {};
        curBooking.requestedDrivers = {};
        curBooking.driverEstimates = {};
        curBooking.selectedBid = {};
      }
      dispatch(updateBooking(curBooking));

      setBidModalStatus(false);
    }
    if (curBooking.payment_mode === "cash") {
      let curBooking = { ...rowData };
      if (rowData.status === "PAYMENT_PENDING") {
        curBooking.status = "NEW";
      } else if (rowData.status === "PENDING") {
        curBooking.status = "PAID";
      } else if (rowData.status === "NEW") {
        curBooking.status = "ACCEPTED";
      }

      curBooking.customer_paid =
        curBooking.status === "ACCEPTED"
          ? 0
          : parseFloat(curBooking.trip_cost).toFixed(settings.decimal);
      curBooking.discount = 0;
      curBooking.usedWalletMoney = 0;
      curBooking.cardPaymentAmount = 0;
      curBooking.cashPaymentAmount =
        curBooking.status === "ACCEPTED"
          ? 0
          : parseFloat(curBooking.trip_cost).toFixed(settings.decimal);
      curBooking.payableAmount =
        curBooking.status === "ACCEPTED"
          ? parseFloat(curBooking.selectedBid.trip_cost).toFixed(
              settings.decimal
            )
          : parseFloat(curBooking.trip_cost).toFixed(settings.decimal);

      if (curBooking.status === "ACCEPTED") {
        curBooking.driver = curBooking.selectedBid.driver;
        curBooking.driver_image = curBooking.selectedBid.driver_image;
        curBooking.driver_name = curBooking.selectedBid.driver_name;
        curBooking.driver_contact = curBooking.selectedBid.driver_contact;
        curBooking.driver_token = curBooking.selectedBid.driver_token;
        curBooking.vehicle_number = curBooking.selectedBid.vehicle_number;
        curBooking.vehicleModel = curBooking.selectedBid.vehicleModel;
        curBooking.vehicleMake = curBooking.selectedBid.vehicleMake;
        curBooking.driverRating = curBooking.selectedBid.driverRating;
        curBooking.trip_cost = curBooking.selectedBid.trip_cost;
        curBooking.convenience_fees = curBooking.selectedBid.convenience_fees;
        curBooking.driver_share = curBooking.selectedBid.driver_share;
        curBooking.fleetadmin = curBooking.selectedBid.fleetadmin ? curBooking.selectedBid.fleetadmin : null;
        curBooking.fleetCommission= curBooking.selectedBid.fleetadmin ? ((parseFloat( curBooking.selectedBid.trip_cost) - parseFloat(curBooking.selectedBid.convenience_fees)) * parseFloat(curBooking.fleet_admin_comission) / 100).toFixed(2):null;
        curBooking.driverOffers = {};
        curBooking.requestedDrivers = {};
        curBooking.driverEstimates = {};
        curBooking.selectedBid = {};
      }
      dispatch(updateBooking(curBooking));
      setBidModalStatus(false);
    }
  };

  const handleWalletPayment = (e) => {
    e.preventDefault();
    let curBooking = { ...selectedBooking };
    let paymentCost = curBooking.selectedBid
      ? curBooking.selectedBid.trip_cost
      : curBooking.trip_cost;
    if (parseFloat(paymentCost) > parseFloat(auth.profile.walletBalance)) {
      setCommonAlert({ open: true, msg: t("wallet_balance_low") });
    } else {
      curBooking.prepaid =
        curBooking.status === "NEW" || curBooking.status === "PAYMENT_PENDING"
          ? true
          : false;
      curBooking.status = curBooking.status === "NEW" ? "ACCEPTED" : "PAID";
      curBooking.customer_paid = parseFloat(paymentCost).toFixed(
        settings.decimal
      );
      curBooking.discount = 0;
      curBooking.usedWalletMoney = parseFloat(paymentCost).toFixed(
        settings.decimal
      );
      curBooking.cardPaymentAmount = 0;
      curBooking.cashPaymentAmount = 0;
      curBooking.payableAmount = 0;
      curBooking.promo_applied = false;
      curBooking.promo_details = null;
      curBooking.paymentPacket = null;
      curBooking.driver = curBooking.selectedBid
        ? curBooking.selectedBid.driver
        : curBooking.driver;
      curBooking.driver_image = curBooking.selectedBid
        ? curBooking.selectedBid.driver_image
        : curBooking.driver_image;
      curBooking.driver_name = curBooking.selectedBid
        ? curBooking.selectedBid.driver_name
        : curBooking.driver_name;
      curBooking.driver_contact = curBooking.selectedBid
        ? curBooking.selectedBid.driver_contact
        : curBooking.driver_contact;
      curBooking.driver_token = curBooking.selectedBid
        ? curBooking.selectedBid.driver_token
        : curBooking.driver_token;
      curBooking.vehicle_number = curBooking.selectedBid
        ? curBooking.selectedBid.vehicle_number
        : curBooking.vehicle_number;
      curBooking.vehicleModel = curBooking.selectedBid
        ? curBooking.selectedBid.vehicleModel
        : curBooking.vehicleModel;
      curBooking.vehicleMake = curBooking.selectedBid
        ? curBooking.selectedBid.vehicleModel
        : curBooking.vehicleModel;
      curBooking.driverRating = curBooking.selectedBid
        ? curBooking.selectedBid.driverRating
        : curBooking.driverRating;
      curBooking.trip_cost = curBooking.selectedBid
        ? curBooking.selectedBid.trip_cost
        : curBooking.trip_cost;
      curBooking.convenience_fees = curBooking.selectedBid
        ? curBooking.selectedBid.convenience_fees
        : curBooking.convenience_fees;
      curBooking.driver_share = curBooking.selectedBid
        ? curBooking.selectedBid.driver_share
        : curBooking.driver_share;
      curBooking.driverOffers = null;
      curBooking.requestedDrivers = null;
      curBooking.driverEstimates = null;
      curBooking.selectedBid = null;

      dispatch(updateBooking(curBooking));

      setTimeout(() => {
        setWalletModalStatus(false);
      }, 1500);
    }
  };

  const handleBidModalClose = () => {
    setBidModalStatus(false);
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const inputStyle = { fontFamily: FONT_FAMILY, };

  const generateBookingPDF = async (booking, settings, t) => {
    const doc = new jsPDF();
    
    // Función para obtener la URL del mapa estático
    const getStaticMapUrl = (origin, destination) => {
        const apiKey = settings.googleApiKey; // Asegúrate de tener la API key configurada
        const encodedOrigin = encodeURIComponent(origin);
        const encodedDestination = encodeURIComponent(destination);
        return `https://maps.googleapis.com/maps/api/staticmap?size=600x300&markers=color:green|label:A|${encodedOrigin}&markers=color:red|label:B|${encodedDestination}&path=color:0x0000ff|weight:5|${encodedOrigin}|${encodedDestination}&key=${apiKey}`;
    };

    // Función para agregar imagen al PDF
    const addImageToPdf = (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                const imageData = canvas.toDataURL('image/png');
                resolve(imageData);
            };
            img.onerror = reject;
            img.src = imageUrl;
        });
    };

    // Encabezado con logo y título
    doc.setFontSize(20);
    doc.text(t('Ruta de Entrega - Guía para Repartidor'), 105, 20, {align: 'center'});
    
    let y = 40;
    
    // Información del pedido
    doc.setFillColor(240, 240, 240);
    doc.rect(15, y-5, 180, 12, 'F');
    doc.setTextColor(0, 0, 0);
    doc.setFontSize(14);
    doc.text(t('Información del Pedido'), 20, y);
    doc.setFontSize(12);
    y += 15;

    doc.text(`${t('Referencia')}: ${booking.reference}`, 20, y);
    y += 10;
    doc.text(`${t('Fecha y Hora de Recogida')}: ${new Date(booking.bookingDate).toLocaleString()}`, 20, y);
    y += 15;

    // Sección de ruta con mapa
    doc.setFillColor(240, 240, 240);
    doc.rect(15, y-5, 180, 12, 'F');
    doc.setFontSize(14);
    doc.text(t('Ruta de Entrega'), 20, y);
    doc.setFontSize(12);
    y += 15;

    // Agregar mapa estático
    try {
        const mapUrl = getStaticMapUrl(booking.pickupAddress, booking.dropAddress);
        const imageData = await addImageToPdf(mapUrl);
        doc.addImage(imageData, 'PNG', 15, y, 180, 90);
        y += 95;
    } catch (error) {
        console.error('Error al cargar el mapa:', error);
        doc.text(t('No se pudo cargar el mapa'), 20, y);
        y += 15;
    }

    // Direcciones detalladas
    doc.setFillColor(240, 240, 240);
    doc.rect(15, y-5, 180, 12, 'F');
    doc.setFontSize(14);
    doc.text(t('Direcciones Detalladas'), 20, y);
    doc.setFontSize(12);
    y += 15;

    // Punto de recogida con ícono A
    doc.setTextColor(0, 100, 0);
    doc.text('A - ' + t('Punto de Recogida') + ':', 20, y);
    doc.setTextColor(0, 0, 0);
    doc.text(booking.pickupAddress, 35, y+10, {maxWidth: 150});
    y += 25;

    // Punto de entrega con ícono B
    doc.setTextColor(200, 0, 0);
    doc.text('B - ' + t('Punto de Entrega') + ':', 20, y);
    doc.setTextColor(0, 0, 0);
    doc.text(booking.dropAddress, 35, y+10, {maxWidth: 150});
    y += 25;

    // Detalles importantes para el repartidor
    doc.setFillColor(255, 250, 205);
    doc.rect(15, y-5, 180, 12, 'F');
    doc.setFontSize(14);
    doc.text(t('Información Importante'), 20, y);
    doc.setFontSize(12);
    y += 15;

    doc.text(`${t('Distancia Total')}: ${booking.distance ? parseFloat(booking.distance).toFixed(2) + (settings.convert_to_mile ? ' mi' : ' km') : t('no_distance')}`, 20, y);
    y += 10;
    doc.text(`${t('Tiempo Estimado')}: ${booking.estimatedTime || t('No disponible')}`, 20, y);
    y += 10;

    // Instrucciones especiales si existen
    if (booking.deliveryInstructions) {
        doc.setFillColor(255, 240, 240);
        doc.rect(15, y-5, 180, 12, 'F');
        doc.setFontSize(14);
        doc.text(t('Instrucciones Especiales de Entrega'), 20, y);
        doc.setFontSize(12);
        y += 15;
        doc.text(booking.deliveryInstructions, 20, y, {maxWidth: 170});
        y += 20;
    }

    // Información de contacto
    doc.setFillColor(240, 240, 240);
    doc.rect(15, y-5, 180, 12, 'F');
    doc.setFontSize(14);
    doc.text(t('Información de Contacto'), 20, y);
    doc.setFontSize(12);
    y += 15;

    if (booking.customer_name) {
        doc.text(`${t('Cliente')}: ${booking.customer_name}`, 20, y);
        y += 10;
    }
    
    if (booking.customer_contact) {
        doc.text(`${t('Teléfono Cliente')}: ${settings.AllowCriticalEditsAdmin ? booking.customer_contact : t('hidden_demo')}`, 20, y);
        y += 10;
    }

    // Pie de página con notas importantes
    doc.setFillColor(240, 240, 240);
    doc.rect(15, 260, 180, 25, 'F');
    doc.setFontSize(10);
    doc.text([
        t('Notas importantes:'),
        '- ' + t('Confirma la dirección exacta al llegar'),
        '- ' + t('Sigue las instrucciones especiales de entrega'),
        '- ' + t('Documento generado el') + ': ' + new Date().toLocaleString()
    ], 20, 265);

    // Guardar el PDF
    doc.save(`ruta-entrega-${booking.reference}.pdf`);
  };

  // Función para exportar reporte detallado
  const exportDetailedReport = () => {
    if (!data || data.length === 0) {
      setCommonAlert({
        open: true,
        msg: t("no_data_to_export")
      });
      return;
    }

    // Función para escapar correctamente campos CSV
    const escapeCSV = (field) => {
      if (field === null || field === undefined) return '';
      
      // Convertir a string si no lo es
      const stringField = String(field);
      
      // Si el campo contiene comas, comillas o saltos de línea, debe estar entre comillas
      // También debemos duplicar las comillas dentro del campo
      if (stringField.includes(',') || stringField.includes('"') || stringField.includes('\n')) {
        return '"' + stringField.replace(/"/g, '""') + '"';
      }
      
      return stringField;
    };

    // Crear encabezados para el reporte
    const headers = [
      t('booking_ref'),
      t('customer_name'),
      t('booking_date'),
      t('car_type'),
      t('assign_driver'),
      t('booking_status_web'),
      t('trip_cost'),
      t('distance'), // Distancia recorrida
      t('pickup_address'),
      t('drop_address'),
      t('trip_start_time'),
      t('trip_end_time'),
      t('trip_duration'), // Duración del viaje en minutos
    ];

    // Preparar los datos para el reporte
    const reportData = data.map(booking => {
      // Calcular duración si hay tiempo de inicio y fin
      let duration = '';
      if (booking.tripdate && booking.completedAt) {
        const startTime = new Date(booking.tripdate);
        const endTime = new Date(booking.completedAt);
        const durationMs = endTime - startTime;
        const durationMinutes = Math.floor(durationMs / 60000);
        duration = durationMinutes + ' ' + t('minutes');
      }

      // Formatear fechas sin comas para evitar problemas en CSV
      const bookingDateFormatted = booking.bookingDate ? 
        moment(booking.bookingDate).format('YYYY-MM-DD HH:mm:ss') : '';
      const tripStartFormatted = booking.tripdate ? 
        moment(booking.tripdate).format('YYYY-MM-DD HH:mm:ss') : '';
      const tripEndFormatted = booking.completedAt ? 
        moment(booking.completedAt).format('YYYY-MM-DD HH:mm:ss') : '';

      // Formatear el costo del viaje
      const tripCost = booking.trip_cost ? 
        (settings.swipe_symbol ? 
          booking.trip_cost + " " + settings.symbol : 
          settings.symbol + " " + booking.trip_cost) : 
        '';

      // Formatear la distancia recorrida
      const distanceFormatted = booking.distance ? 
        (settings.convert_to_mile ? 
          (parseFloat(booking.distance) * 0.621371).toFixed(2) + ' mi' : 
          parseFloat(booking.distance).toFixed(2) + ' km') : 
        '';

      // Crear el array de datos, aplicando escapeCSV a cada campo
      return [
        escapeCSV(booking.reference || ''),
        escapeCSV(booking.customer_name || ''),
        escapeCSV(bookingDateFormatted),
        escapeCSV(booking.carType || ''),
        escapeCSV(booking.driver_name || ''),
        escapeCSV(booking.status || ''),
        escapeCSV(tripCost),
        escapeCSV(distanceFormatted),
        escapeCSV(booking.pickupAddress || ''),
        escapeCSV(booking.dropAddress || ''),
        escapeCSV(tripStartFormatted),
        escapeCSV(tripEndFormatted),
        escapeCSV(duration)
      ];
    });

    // Aplicar escapeCSV a los encabezados también
    const escapedHeaders = headers.map(header => escapeCSV(header));

    // Generar el contenido CSV
    const csvContent = [escapedHeaders, ...reportData]
      .map(e => e.join(","))
      .join("\n");

    // Descargar el CSV
    downloadCsv(csvContent, "reporte_detallado_entregas.csv");
  };

  return bookinglistdata.loading ? (
    <CircularLoading />
  ) : (
    <div>
      <ThemeProvider theme={theme}>
        {/* Botón para exportar reporte detallado */}
        <Paper 
          elevation={3} 
          style={{
            padding: '10px',
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            backgroundColor: '#f5f5f5'
          }}
        >
          <Tooltip title="Exportar reporte detallado">
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={exportDetailedReport}
              style={{ backgroundColor: colors.BLACK, fontFamily: FONT_FAMILY, color: 'white' }}
            >
              Exportar reporte detallado
            </Button>
          </Tooltip>
        </Paper>
        
        <MaterialTable
          title={t("booking_title")}
          style={{
            direction: isRTL === "rtl" ? "rtl" : "ltr",
            borderRadius: "8px",
            boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
          }}
          columns={columns}
          data={data}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 15, 20],
            toolbarbuttonalignment: "right",
            exportCsv: (columns, data) => {
              let hArray = [];
              const headerRow = columns.map((col) => {
                if (typeof col.title === "object") {
                  return col.title.props.text;
                }
                hArray.push(col.field);
                return col.title;
              });
              const dataRows = data.map(({ tableData, ...row }) => {
                row.bookingDate =
                  new Date(row.bookingDate).toLocaleDateString() +
                  " " +
                  new Date(row.bookingDate).toLocaleTimeString();
                row.tripdate =
                  new Date(row.tripdate).toLocaleDateString() +
                  " " +
                  new Date(row.tripdate).toLocaleTimeString();
                row.pickupAddress = row.pickupAddress.replace(/,/g, ";");
                row.dropAddress = row.dropAddress.replace(/,/g, ";");
                let dArr = [];
                for (let i = 0; i < hArray.length; i++) {
                  dArr.push(row[hArray[i]]);
                }
                return Object.values(dArr);
              });
              const { exportDelimiter } = ",";
              const delimiter = exportDelimiter ? exportDelimiter : ",";
              const csvContent = [headerRow, ...dataRows]
                .map((e) => e.join(delimiter))
                .join("\n");
              const csvFileName = "download.csv";
              downloadCsv(csvContent, csvFileName);
            },
            exportButton: {
              csv: settings.AllowCriticalEditsAdmin,
              pdf: false,
            },
            maxColumnSort: "all_columns",
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow === rowData.tableData.id ? colors.ROW_SELECTED :colors.WHITE
            }),
            maxBodyHeight: "calc(100vh - 199.27px)",
            headerStyle: {
              position: "sticky",
              top: "0px",
              backgroundColor: SECONDORY_COLOR,
              color: colors.Black,
              fontWeight: "bold ",
              textAlign: "center",
              zIndex: 1,
              border: `1px solid ${colors.TABLE_BORDER}`,
              
            },
            cellStyle: {
              border: `1px solid ${colors.TABLE_BORDER}`,
              textAlign: "center",
              margin: "auto",
            },
            actionsColumnIndex: -1,
          }}
          localization={{
            toolbar: {
              searchPlaceholder: t("search"),
              exportTitle: t("export"),
              exportCSVName: t("export"),
            },
            header: {
              actions: t("actions"),
            },
            pagination: {
              labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
              firstTooltip: t("first_page_tooltip"),
              previousTooltip: t("previous_page_tooltip"),
              nextTooltip: t("next_page_tooltip"),
              lastTooltip: t("last_page_tooltip"),
            },
          }}
          actions={[
            (rowData) => ({
              icon: () => (
                <div
                  className={classes.action}
                  style={{
                    color: colors.Header_Background,
                    backgroundColor:
                      rowData.status === "NEW" || rowData.status === "ACCEPTED"
                        ? colors.Action_Button_Back
                        : colors.BORDER_BACKGROUND,
                  }}
                >
                  <CancelIcon />
                  <Typography variant="subtitle2">
                    {t("cancel_booking")}
                  </Typography>
                </div>
              ),
              disabled:
                rowData.status === "NEW" ||
                rowData.status === "ACCEPTED"
                  ? false
                  : true,
              onClick: (event, rowData) => {
                if (
                  settings.AllowCriticalEditsAdmin &&
                  (role === "customer" ||
                    role === "admin" ||
                    role === "fleetadmin")
                ) {
                  if (rowData.status === "NEW" || rowData.status === "ACCEPTED") {
                    setSelectedBooking(rowData);
                    setOpenConfirm(true);
                  } else {
                    setTimeout(() => {
                      dispatch(
                        cancelBooking({
                          reason: t("cancelled_incomplete_booking"),
                          booking: rowData,
                          cancelledBy: role,
                        })
                      );
                    }, 1500);
                  }
                } else {
                  alert(t("demo_mode"));
                }
              },
            }),
            (rowData) =>
              rowData.status === "NEW" &&
              role === "customer" &&
              rowData.driverOffers
                ? {
                    icon: () => (
                      <div
                        className={classes.action}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          color:
                            rowData.status === "NEW"
                              ? colors.Header_Background
                              : colors.Table_Header,
                          backgroundColor:
                            rowData.status === "NEW"
                              ? colors.Action_Button_Back
                              : colors.BORDER_BACKGROUND,
                        }}
                      >
                        <PlaylistAddCheckIcon />
                        <Typography variant="subtitle2">
                          {t("selectBid")}
                        </Typography>
                      </div>
                    ),
                    onClick: (event, rowData) => {
                      setRowIndex(rowData.tableData.id);
                      setSelectedBooking(rowData);
                      setBidModalStatus(true);
                    },
                  }
                : null,
            (rowData) =>
              rowData.status === "NEW" &&
              (role === "admin" || role === "fleetadmin") &&
              settings.autoDispatch === false &&
              showEst
                ? {
                    icon: () => (
                      <div
                        className={classes.action}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          color:
                            rowData.status === "NEW"
                              ? colors.Header_Background
                              : colors.Table_Header,
                          backgroundColor:
                            rowData.status === "NEW"
                              ? colors.Action_Button_Back
                              : colors.BORDER_BACKGROUND,
                        }}
                      >
                        <PersonAddIcon />
                        <Typography variant="subtitle2">
                          {t("assign_driver")}
                        </Typography>
                      </div>
                    ),
                    onClick: (event, rowData) => {
                      setOpen(true);
                      setRowIndex(rowData.tableData.id);
                    },
                  }
                : null,
            (rowData) =>
              (rowData.status === "PAYMENT_PENDING" || rowData.status ===  "PENDING") && role === "customer"
                ? {
                    icon: () => (
                      <div
                        className={classes.action}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          color:
                            (rowData.status === "PAYMENT_PENDING" || rowData.status === "PENDING")
                              ? colors.Header_Background
                              : colors.Table_Header,
                          backgroundColor:
                            (rowData.status === "PAYMENT_PENDING" || rowData.status === "PENDING")
                              ? colors.Action_Button_Back
                              : colors.BORDER_BACKGROUND,
                        }}
                      >
                        <PaymentIcon />
                        <Typography variant="subtitle2">
                          {t("paynow_button")}
                        </Typography>
                      </div>
                    ),
                    onClick: (event, rowData) => {
                      processPayment(rowData);
                    },
                  }
                : null,
            (rowData) =>
              rowData.status === "STARTED" && role === "admin"
                ? {
                    icon: () => (
                      <div
                        className={classes.action}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          color:
                            rowData.status === "STARTED"
                              ? colors.Header_Background
                              : colors.Table_Header,
                          backgroundColor:
                            rowData.status === "STARTED"
                              ? colors.Action_Button_Back
                              : colors.BORDER_BACKGROUND,
                        }}
                      >
                        <PlaylistAddCheckIcon />
                        <Typography variant="subtitle2">
                          {t("force_end")}
                        </Typography>
                      </div>
                    ),
                    onClick: (event, rowData) => {
                      dispatch(forceEndBooking(rowData));
                    },
                  }
                : null,
            {
              icon: "info",
              tooltip: t("booking_details"),
              onClick: (event, rowData) => {
                navigate(`/bookings/bookingdetails/${rowData.id}`);
              },
            },
            {
              icon: () => (
                <div
                  className={classes.action}
                  style={{
                    display: "flex", 
                    alignItems: "center",
                    color: colors.Header_Background,
                    backgroundColor: colors.Action_Button_Back,
                  }}
                >
                  <PictureAsPdfIcon />
                  <Typography variant="subtitle2">
                    {t("Descargar PDF")}
                  </Typography>
                </div>
              ),
              onClick: (event, rowData) => {
                generateBookingPDF(rowData, settings, t);
              },
            }
          ]}
        />
      </ThemeProvider>
      <BidModal
        ref={rootRef.current}
        role={role}
        selectedBooking={selectedBooking}
        bidModalStatus={bidModalStatus}
        handleBidModalClose={handleBidModalClose}
        classes={classes}
        settings={settings}
        acceptBid={() => {
          const bookingObj = acceptBid(selectedBooking, selectedBidder);
          processPayment(bookingObj);
          handleBidModalClose();
        }}
        selectedBidder={selectedBidder}
        handleChange={handleChange}
      />
      {selectedBooking &&
      selectedBooking.payment_mode === "wallet" &&
      (selectedBooking.status === "PENDING" ||
        (selectedBooking &&
          selectedBooking.selectedBid &&
          selectedBooking.status === "NEW")) &&
      role === "customer" ? (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={walletModalStatus}
          onClose={handleWalletModalClose}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <Grid container spacing={2} className={classes.paper}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ marginBottom: "20px" }}
            >
              <Typography style={{ fontWeight: "bolder", marginBottom: 10 ,fontFamily: FONT_FAMILY,}}>
                {t("payment")}
              </Typography>
              {selectedBooking ? (
                <Typography color={"primary"} style={{ fontSize: 30 ,fontFamily: FONT_FAMILY}}>
                  {settings.swipe_symbol === false
                    ? settings.symbol + selectedBooking.trip_cost
                    : selectedBooking.trip_cost + settings.symbol}
                </Typography>
              ) : null}
              <Typography style={{fontFamily: FONT_FAMILY}}>
                {t("use_wallet_balance") +
                  " " +
                  (settings.swipe_symbol === false
                    ? settings.symbol + auth.profile.walletBalance
                    : auth.profile.walletBalance + settings.symbol) +
                  ")"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                onClick={handleWalletModalClose}
                variant="contained"
                style={{backgroundColor:colors.RED, fontFamily: FONT_FAMILY }}
              >
                {t("cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: 10 , backgroundColor:colors.GREEN, fontFamily: FONT_FAMILY }}
                onClick={handleWalletPayment}
              >
                {t("paynow_button")}
              </Button>
            </Grid>
          </Grid>
        </Modal>
      ) : null}
      {selectedBooking &&
      selectedBooking.paymentPacket &&
      selectedBooking.payment_mode === "card" &&
      ((selectedBooking.status === "PENDING" || selectedBooking.status === "PAYMENT_PENDING") ||
        (selectedBooking &&
          selectedBooking.selectedBid &&
          selectedBooking.status === "NEW")) &&
      role === "customer" ? (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={paymentModalStatus}
          onClose={handlePaymentModalClose}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <Grid container spacing={2} className={classes.paper}>
            {providers && selectedProvider && selectedBooking ? (
              <form action={selectedProvider.link} method="POST">
                <input
                  type="hidden"
                  name="order_id"
                  value={selectedBooking.id}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="amount"
                  value={
                    selectedBooking.selectedBid
                      ? selectedBooking.selectedBid.trip_cost
                      : selectedBooking.trip_cost
                  }
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="currency"
                  value={settings.code}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="product_name"
                  value={t("bookingPayment")}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="first_name"
                  value={auth.profile.firstName}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="last_name"
                  value={auth.profile.lastName}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="quantity"
                  value={1}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="cust_id"
                  value={selectedBooking.customer}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="mobile_no"
                  value={selectedBooking.customer_contact}
                  style={inputStyle}
                />
                <input
                  type="hidden"
                  name="email"
                  value={selectedBooking.customer_email}
                  style={inputStyle}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  style={{ marginBottom: "20px" }}
                >
               <FormControl fullWidth style={{ fontFamily: FONT_FAMILY }}>
                  <FormLabel component="legend" style={{ fontFamily: FONT_FAMILY }}> {t("payment")} </FormLabel>
                  <Select
                    id="selectedProviderIndex"
                    name="selectedProviderIndex"
                    value={selectedProviderIndex}
                    label={t("payment")}
                    onChange={handleChange}
                    style={{ textAlign: isRTL === "rtl" ? "right" : "left", fontFamily: FONT_FAMILY }}
                    inputProps={{ "aria-label": "Without label", style: { fontFamily: FONT_FAMILY } }}
                  >
                    {providers.map((provider, index) => (
                      <MenuItem key={provider.name} value={index} style={{ fontFamily: FONT_FAMILY }}>
                        <img
                          style={{ height: 24, margin: 7 }}
                          src={icons[provider.name]}
                          alt={provider.name}
                        />{" "}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    onClick={handlePaymentModalClose}
                    variant="contained"
                    style={{  backgroundColor:colors.RED,fontFamily: FONT_FAMILY }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginLeft: 10 , backgroundColor:colors.GREEN, fontFamily: FONT_FAMILY }}
                    onClick={handlePaymentModalClose}
                  >
                    {t("paynow_button")}
                  </Button>
                </Grid>
              </form>
            ) : null}
          </Grid>
        </Modal>
      ) : null}
      <ConfirmationDialogRaw
        open={openConfirm}
        onClose={onConfirmClose}
        value={""}
      />
      {users && data && rowIndex >= 0 ? (
        <Modal
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          onClose={handleClose}
          open={open}
          className={classes.modal}
          container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.title}
                  style={{ textAlign: isRTL === "rtl" ? "right" : "left", fontFamily: FONT_FAMILY}}
                >
                  {t("select_driver")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <UsersCombo
                  className={classes.items}
                  placeholder={t("select_user")}
                  users={users.filter(
                    (usr) => usr.carType === data[rowIndex].carType
                  )}
                  value={userCombo}
                  onChange={(event, newValue) => {
                    setUserCombo(newValue);
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{
                  direction: isRTL === "rtl" ? "rtl" : "ltr",
                  marginLeft: isRTL === "rtl" ? "65%" : 0,
                }}
              >
                <Button
                  onClick={handleClose}
                  variant="contained"
                  style={{  backgroundColor:colors.RED, fontFamily: FONT_FAMILY }}
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={assignDriver}
                  variant="contained"
                  color="primary"
                  style={{
                    ...(isRTL === "rtl" ? { marginRight: 10 } : { marginLeft: 10 }),
                    backgroundColor:colors.RED,
                    fontFamily: FONT_FAMILY,
                  }}
                >
                  {t("assign")}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Modal>
      ) : null}
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default BookingHistory;
